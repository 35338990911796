@import "reset";
@import "setting";
@import "module";
@import "common";
@media #{$middle}{
	}//@media #{$middle}{	
@media #{$large}{
	}//@media #{$large}{
@media #{$retina}{}
@media #{$small}{
	}//@media #{$middle}{


.btn01{
	display: block;
	max-width: 200px;
	margin: 0 auto;
	a{
		text-align: center;
		display: block;
		background: $base_color;
		color: #fff;
		padding: 10px 40px;
		position: relative;
		&:after{
			content: "";
			position: absolute;
			background: url(../img/icn_arrow02.png) no-repeat center left;
			background-size: 100%;
			width: 5px;
			height: 9px;
			margin-left: 4px;
			top: 20px;
			right: 16px;
			transition: .3s ease-in-out;
		}
		&:hover:after{
			top: 20px;
			right: 8px;
		}
	}
}

.btn02{
	display: block;
	max-width: 200px;
	margin: 0 auto;
	a{
		text-align: center;
		display: block;
		border: 2px solid #fff;
		color: #fff;
		padding: 10px 40px;
		position: relative;
		&:after{
			content: "";
			position: absolute;
			background: url(../img/icn_arrow02.png) no-repeat center left;
			background-size: 100%;
			width: 5px;
			height: 9px;
			margin-left: 4px;
			top: 20px;
			right: 16px;
			transition: .3s ease-in-out;
		}
		&:hover:after{
			top: 20px;
			right: 8px;
		}
	}
}


body{
	position: relative;
}

.body_line{
	position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -10;
	
	.container_in{
		height: 100%;
    margin: 0 auto;
		position: relative;
		span{
			    background-color: rgba(238,238,238,0.5);
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 1px;
			&:nth-child(1){
				left: 2%;
			}
			&:nth-child(2){
				left: 33%;
			}
			&:nth-child(3){
				left: 66%;
			}
			&:nth-child(4){
				left: 98%;
			}
		}
	}
}

/*=============== header ==============*/
header {
	width: 100%;
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 9999;
	background: #fff;
	transition: 1.0s;
	text-decoration: none;
	
	a{
	transition: 1.0s;
	text-decoration: none;
	color: #000;
	}

	.container{
		.wrapper {
		
		.header_bar {
			height: 10px;
			background: #296eb2;
		}
		}
	}
	
	.container_in{
		
	
	ul{
		li{
			display: inline-block;
			margin-right: 16px;
			font-weight: bold;
		}
	}
		
		#global_nav {
			padding: 30px 0;
			width: 60%;
			display: inline-block;
			
			#global_nav_contact a:before {
			content: "";
			display: inline-block;
    		vertical-align: text-bottom;
			background: url(../img/header_icn_mail.png) no-repeat center left;
			background-size: 100%;
			width: 25px;
			height: 18px;
			margin-right: 4px;
			}
		}
}
		nav{
		}
	
	
		
	.header_rental_btn{
			position: absolute;
			right: 0;
		}
	
	.header_content{
		position: relative;
		padding: 20px 0;
		float: left;
		width: 18%;
		
		.header_logo {
			float: left;
			width: 100%;
			font-weight: bold;
			p{
				font-size: 32px;
			}
}
	}
	}

header .inner {
	position: relative;
	padding: 30px 0;
}
#global_nav ul {
	width: 100%;
	list-style: none;
	font-size: 16px;
	float: left;
}
#global_nav ul li {
	float: left;
}
#global_nav ul li a {
	padding: 0 8px;
	font-size: 16px;
}
#global_nav ul li a img {
	vertical-align: middle;
}

.header_tel{
	float: right;
	padding: 30px 0;
	margin-right: 20px;
	width: 18%;
	}
/* Toggle Button */
#nav_toggle {
	display: none;
	position: absolute;
	right: 12px;
	top: 40px;
	width: 34px;
	height: 36px;
	cursor: pointer;
	z-index: 101;
}
#nav_toggle div {
	position: relative;
}
#nav_toggle span {
	display: block;
	position: absolute;
	height: 4px;
	width: 100%;
	background: #39afba;
	left: 0;
	-webkit-transition: .35s ease-in-out;
	-moz-transition: .35s ease-in-out;
	transition: .35s ease-in-out;
}
#nav_toggle span:nth-child(1) {
	top: 0;
}
#nav_toggle span:nth-child(2) {
	top: 11px;
}
#nav_toggle span:nth-child(3) {
	top: 22px;
}

@media screen and (min-width: 768px) {
header li {
}
header ul li a {
	display: block;
	position: relative;
	text-decoration: none;
}
}

@media screen and (max-width: 767px) {
header{
	width: 100%;
	padding: 0;
	top: 0;
	position: fixed;
	margin-top: 0;
	
	.container{
	
	.header_bar {
    background: #000;
    color: #fff;
    font-size: 10px;
    padding: 2px 0;
	}
	}
	
	.fixed {
	padding-top: 0;
	background: transparent;
	}
	.header_content{
		padding: 10px 0;
	.header_logo {
		p{
			font-size: 16px;
		}
	}
	}
}
#mobile-head {
	background: #fff; width: 100%;
	z-index: 999;
	position: relative;
}
header .container_in #global_nav {
	position: absolute;
	/* 開いてないときは画面外に配置 */
	top: -714px;
	left: 0;
	background:#f6f6f6;
	width: 100%;
	text-align: center;
	padding: 10px 0;
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	width: 100%;
}
.header_tel {
    float: right;
    padding: 12px 0 0;
    margin-right: 50px;
    width: 38%;
}
#global_nav ul {
	list-style: none;
	position: static;
	right: 0;
	bottom: 0;
	font-size: 14px;
	float: none;
}
#global_nav ul li {
	float: none;
	position: static;
	display: block;
}
header #global_nav ul li a, header.fixed #global_nav ul li a {
	width: 100%;
	display: block;
	padding: 16px 20px;
	font-size: 13px;
}
#nav_toggle {
	display: block;
	right: 4%;
	top:10px;
}
/* #nav_toggle 切り替えアニメーション */
.open #nav_toggle span:nth-child(1) {
	top: 11px;
	-webkit-transform: rotate(315deg);
	-moz-transform: rotate(315deg);
	transform: rotate(315deg);
}
.open #nav_toggle span:nth-child(2) {
	width: 0;
	left: 50%;
}
.open #nav_toggle span:nth-child(3) {
	top: 11px;
	-webkit-transform: rotate(-315deg);
	-moz-transform: rotate(-315deg);
	transform: rotate(-315deg);
}
/* #global_nav スライドアニメーション */
.open #global_nav {
	/* #global_nav top + #mobile-head height */
	-moz-transform: translateY(756px);
	-webkit-transform: translateY(756px);
	transform: translateY(756px);
}
}
/*-----------------------------------------------------------------------------------*/
/*.mainContents----------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------*/




/*-----------------------------------------------------------------------------------*/
/*.footer-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------*/
footer{
	.container_in{
	background: #f0f0f0;
	padding: 20px;
	color: $font_color;
	margin-bottom: 80px;
	}
	.footer_content{
		margin-bottom: 80px;
		
	.compnay_name{
		font-size: 24px;
	}
	}
	
	.copyright{
		font-size: 12px;
		text-align: center;
	}
}//.footer

@media #{$pc}  {
}
@media #{$sp} {
footer{
	.container_in{
	background: #f0f0f0;
	padding: 20px;
	color: $font_color;
	margin-bottom: 20px;
	}
	.footer_content{
		margin-bottom: 80px;
		
	.compnay_name{
		font-size: 24px;
	}
	}
	
	.copyright{
		font-size: 10px;
		text-align: center;
	}
}//.footer
}

/*-----------------------------------------------------------------------------------*/
/*トップページ----------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------*/
	//メインビジュアル
	#mv{
		position: relative;
		margin-top: 89px;
		margin-bottom: 80px;
		.mv_catch{
			position: absolute;
			width: 70%;
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%,-50%);
			-moz-transform: translate(-50%,-50%);
			-ms-transform: translate(-50%,-50%);
			-o-transform: translate(-50%,-50%);
			transform: translate(-50%,-50%);
		}
	}
	#mv_point{
		position: relative;
		margin-bottom: 10%;
		.mv_point_list{
			position: absolute;
			width: 70%;
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%,-50%);
			-moz-transform: translate(-50%,-50%);
			-ms-transform: translate(-50%,-50%);
			-o-transform: translate(-50%,-50%);
			transform: translate(-50%,-50%);
			
			ul{
				font-size: 0;
  text-align: center;
				li{
					display: inline-block;
  font-size: 12px;
  padding: 0 10px;
					width: 20%;
				}
			}
		}
		}
	
@media #{$pc}  {
}
@media #{$sp} {
	//メインビジュアル:SP
	#mv{
		margin-top: 48px;
		margin-bottom: 48px;
		.mv_catch{
			position: absolute;
			width: 70%;
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%,-150%);
			-moz-transform: translate(-50%,-150%);
			-ms-transform: translate(-50%,-150%);
			-o-transform: translate(-50%,-150%);
			transform: translate(-50%,-150%);
		}
	}
		#mv_point{
		position: relative;
		margin-bottom: 10%;
		.mv_point_list{
			position: absolute;
			width: 100%;
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%,-110%);
			-moz-transform: translate(-50%,-110%);
			-ms-transform: translate(-50%,-110%);
			-o-transform: translate(-50%,-110%);
			transform: translate(-50%,-110%);
			
			ul{
				li{
					display: inline-block;
					font-size: 12px;
					padding: 0 10px;
					width: 40%;
				}
			}
		}
		}
}


//日本の明るい未来を照らし出す
#section01{
	position: relative;
	margin-bottom: 220px;
	.wrapper{
	position: relative;
	z-index: 999;
	margin: 0 auto;
	}
	.container_in{
	position: relative;
	top:0;
	.section01_bg{
	position: absolute;
	right: 0;
	top: -220px;
	width: 70%;
	padding: 140px 0;
	background-image: url(../img/section01_bg.jpg);
    background-size:15px;
    background-position: 50% 50%;
	}
	}
}	
@media #{$pc}  {
}
@media #{$sp} {
#section01{
	position: relative;
	margin-bottom: 120px;
	.wrapper{
	position: relative;
	z-index: 999;
	}
	.container_in{
	position: relative;
	top:0;
	.section01_bg{
	position: absolute;
	right: 0;
	top: -220px;
	width: 70%;
	padding: 140px 0;
	background-image: url(../img/section01_bg.jpg);
    background-size:15px;
    background-position: 50% 50%;
	}
	}
}	
}


//弊社が選ばれる理由
#section02{
	position: relative;
	margin-bottom: 120px;
	h2{
	text-align: center;
	margin-bottom: 120px;
	}
	.wrapper{
	}
	.container_in{
		background: #f9fdff;
		padding: 80px 0;
	.section02_list{
		width: 92%;
		margin: 0 auto;
		ul{
			li{
				width: 30%;
				margin-right: 5%;
				float: left;
				border: 1px solid #999;
				box-sizing: border-box;
				padding: 80px 2% 2%;
				position: relative;
				&:last-child{
				margin-right: 0;
				}
				h3{
				text-align: center;
				margin-bottom: 20px;
				}
				img{
				display: block;
				margin: 0 auto 20px;
				}
				.section02_list_num{
				position: absolute;
				top: -45px;
				right: 0;
				left: 0;
				}
			}
		}
	}
	}
}
	
@media #{$pc}  {
}
@media screen and (min-width:768px) and ( max-width:1023px) {
#section02{
	position: relative;
	margin-bottom: 120px;
	h2{
	text-align: center;
	margin-bottom: 120px;
	}
	.wrapper{
	}
	.container_in{
		background: #f9fdff;
		padding: 80px 0;
		margin: 0;
	.section02_list{
		width: 92%;
		margin: 0 auto;
		ul{
			li{
				width: 30%;
				margin-right: 5%;
				float: left;
				border: 1px solid #999;
				box-sizing: border-box;
				padding: 80px 2% 2%;
				position: relative;
				&:last-child{
				margin-right: 0;
				}
				h3{
				text-align: center;
				margin-bottom: 20px;
				}
				img{
				display: block;
				margin: 0 auto 20px;
				}
				.section02_list_num{
				position: absolute;
				top: -45px;
				right: 0;
				left: 0;
				}
			}
		}
	}
	}
}
}
@media #{$sp} {
#section02{
	position: relative;
	margin-bottom: 40px;
	h2{
	text-align: center;
	margin-bottom: 120px;
	}
	.wrapper{
	}
	.container_in{
		background: #f9fdff;
		padding: 40px 0;
	.section02_list{
		width: 92%;
		margin: 0 auto;
		ul{
			li{
				width: 100%;
				margin-right: 0;
				margin-bottom: 80px;
				float: none;
				border: 1px solid #999;
				box-sizing: border-box;
				padding: 80px 4% 4%;
				position: relative;
				&:last-child{
				margin-right: 0;
				margin-bottom: 0;
				}
				h3{
				text-align: center;
				margin-bottom: 20px;
				}
				img{
				display: block;
				margin: 0 auto 20px;
				}
				.section02_list_num{
				position: absolute;
				top: -45px;
				right: 0;
				left: 0;
				}
			}
		}
	}
	}
}
}

//お客様の声
#section03{
	position: relative;
	margin-bottom: 220px;
	h2{
	text-align: center;
	margin-bottom: 120px;
	}
	.container_in{
	.wrapper{
	margin: 0 auto;
	.section03_list{
		ul{
			li{
			margin-bottom: 80px;
			h3{
				border-bottom: 1px solid #000;
				display: inline-block;
				font-size: 20px;
				margin-bottom: 20px;
			}
			.section03_txt{
			font-size: 14px;
			}
			.section03_name{
			font-size: 14px;
			span{
			font-weight: bold;
			margin-bottom: 12px;
			}
			}
			&:nth-child(odd){
				.section03_list_01{
				width: 30%;
				background:#39afba;
				position: relative;
				float: left;
				.section03_photo{
					position: relative;
					top:-30px;
					right: -30px;
				}
				}
				.section03_list_02{
					width: 60%;
					float: right;
					border: 1px solid #999;
					padding: 2%;
					background-image: url(../img/section03_bg.png);
    background-size:7px;
    background-position: 50% 50%;
				}
			}
			&:nth-child(even){
			.section03_list_02{
				width: 30%;
				background:#39afba;
				position: relative;
				float: right;
				.section03_photo{
					position: relative;
					top:-30px;
					right: -30px;
				}
				}
				.section03_list_01{
					width: 60%;
					float: left;
					border: 1px solid #999;
					padding: 2%;
					background-image: url(../img/section03_bg.png);
    background-size:7px;
    background-position: 50% 50%;
				}
			}
			}
			
		}
	}
	}
	}
}
	
@media #{$pc}  {
}
@media screen and (min-width:768px) and ( max-width:1023px) {
}
@media #{$sp} {
#section03{
	position: relative;
	margin-bottom:0;
	h2{
	text-align: center;
	margin-bottom: 80px;
	}
	.container_in{
	.wrapper{
	
	.section03_list{
		ul{
			li{
			margin-bottom:80px;
			h3{
				border-bottom: 1px solid #000;
				display: inline-block;
				font-size: 20px;
				margin-bottom: 20px;
			}
			.section03_txt{
			font-size: 14px;
			}
			.section03_name{
			font-size: 14px;
			span{
			font-weight: bold;
			margin-bottom: 12px;
			}
			}
			&:nth-child(odd){
				.section03_list_01{
				width: 90%;
				margin-bottom: 20px;
				background:#39afba;
				position: relative;
				float: none;
				.section03_photo{
					position: relative;
					top:-30px;
					right: -30px;
				}
				}
				.section03_list_02{
					width: 80%;
					float: none;
					border: 1px solid #999;
					padding: 10%;
					background-image: url(../img/section03_bg.png);
					background-size:7px;
					background-position: 50% 50%;
				}
			}
			&:nth-child(even){
			.section03_list_02{
				width: 90%;
				margin-bottom: 20px;
				background:#39afba;
				position: relative;
				float: none;
				.section03_photo{
					position: relative;
					top:-30px;
					right: -30px;
				}
				}
				.section03_list_01{
					width: 80%;
					float: none;
					border: 1px solid #999;
					padding: 10%;
					background-image: url(../img/section03_bg.png);
					background-size:7px;
					background-position: 50% 50%;
				}
			}
			}
			
		}
	}
	}
	}
}
}


//事業案内
#section04{

margin-bottom: 80px;
	h2{
	}
	.container_in{
	padding: 60px 0;
	.wrapper{
	margin: 0 auto;
	section{
	width: 60%;
	}
	}
	}
	.container_in:nth-child(even){
	.wrapper{
	section{
	float: right;
	}
	}
	}
	.section04_01{
	background-image: url(../img/section04_bg01.jpg);
    background-size:cover;
    background-position: 50% 50%;
	}
	.section04_02{
	background-image: url(../img/section04_bg02.jpg);
    background-size:cover;
    background-position: 50% 50%;
	}
	.section04_03{
	background-image: url(../img/section04_bg03.jpg);
    background-size:cover;
    background-position: 50% 50%;
	}
	.section04_04{
	background-image: url(../img/section04_bg04.jpg);
    background-size:cover;
    background-position: 50% 50%;
	}
	.section04_05{
	background-image: url(../img/section04_bg05.jpg);
    background-size:cover;
    background-position: 50% 50%;
	}
}
	
@media #{$pc}  {
}
@media screen and (min-width:768px) and ( max-width:1023px) {
#section04{
margin-bottom: 80px;
	h2{
	}
	.container_in{
	padding: 60px 0;
	.wrapper{
	section{
	width: 60%;
	}
	}
	}
	.container_in:nth-child(even){
	.wrapper{
	section{
	float: right;
	}
	}
	}
	.section04_01{
	background-image: url(../img/section04_bg01.jpg);
    background-size:cover;
    background-position: 50% 50%;
	}
	.section04_02{
	background-image: url(../img/section04_bg02.jpg);
    background-size:cover;
    background-position: 50% 50%;
	}
	.section04_03{
	background-image: url(../img/section04_bg03.jpg);
    background-size:cover;
    background-position: 50% 50%;
	}
	.section04_04{
	background-image: url(../img/section04_bg04.jpg);
    background-size:cover;
    background-position: 50% 50%;
	}
	.section04_05{
	background-image: url(../img/section04_bg05.jpg);
    background-size:cover;
    background-position: 50% 50%;
	}
}
}
@media #{$sp} {
#section04{
margin-bottom: 40px;
	h2{
	}
	.container_in{
	padding: 140px 0 60px 0;
	margin: 0 4% !important;
	.wrapper{
	section{
	width: 100%;
	}
	}
	}
	.container_in:nth-child(even){
	.wrapper{
	section{
	h2{
	text-align: right;
	}
	}
	}
	}
	.section04_01{
	background-size: 150%;
    background-repeat: no-repeat;
    background-position: 100% 0%;
    background-color: #cfd0cf;
	}
	.section04_02{
	background-size: 150%;
    background-repeat: no-repeat;
    background-position:0% 0%;
    background-color: #ebf4fc;
	}
	.section04_03{
	background-size: 150%;
    background-repeat: no-repeat;
    background-position: 100% 0%;
    background-color: #f6f7fa;
	}
	.section04_04{
	background-size: 150%;
    background-repeat: no-repeat;
    background-position:0% 0%;
    background-color: #fff;
	}
	.section04_05{
	background-size: 150%;
    background-repeat: no-repeat;
    background-position: 100% 0%;
    background-color: #f0f0f0;
	}
	}
}

//CTA
#cta{
margin-bottom: 120px;
	.wrapper{
	background: #39afba;
	padding: 4% 0;
	margin: 0 auto;
	h2{
	text-align: center;
	}
	ul{
	padding: 4%;
	li{
	width: 48%;
	margin-right: 4%;
	float: left;
	&:last-child{
	margin-right: 0;
	}
	}
	}
	}
}
	
@media #{$pc}  {
}
@media #{$sp} {
#cta{
margin-bottom: 80px;
	.wrapper{
	background: #39afba;
	padding: 14% 0 0%;
	h2{
	text-align: center;
	}
	ul{
	padding: 4%;
	li{
	width: 100%;
	margin-right:0;
	margin-bottom: 4%;
	float: none;
	&:last-child{
	margin-right: 0;
	}
	}
	}
	}
}
}


//採用情報
#section05{
margin-bottom: 120px;
	.wrapper{
	margin: 0 auto;
	h2{
	text-align: center;
	margin-bottom: 80px;
	}
	input[type="radio"]{display:none;}
.tab_area{font-size:0; margin:0 10px;}
.tab_area label{width:25%; margin:0 5px; display:inline-block; padding:12px 0; color:#999; background:#ddd; text-align:center; font-size:13px; cursor:pointer; transition:ease 0.2s opacity;}
.tab_area label:hover{opacity:0.5;}
.panel_area{background:#fff;}
.tab_panel{width:100%; padding:80px 0; display:none;}
.tab_panel p{font-size:14px; letter-spacing:1px; text-align:center;}
 
#tab1:checked ~ .tab_area .tab1_label{background:#39afba; color:#fff; position: relative;}
#tab1:checked ~ .tab_area .tab1_label:before{
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
 	margin-left: -15px;
	border: 15px solid transparent;
	border-top: 15px solid #39afba;
}
#tab1:checked ~ .panel_area #panel1{display:block;}
#tab2:checked ~ .tab_area .tab2_label{background:#39afba; color:#fff; position: relative;}
#tab2:checked ~ .tab_area .tab2_label:before{
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
 	margin-left: -15px;
	border: 15px solid transparent;
	border-top: 15px solid #39afba;
}
#tab2:checked ~ .panel_area #panel2{display:block;}
	table {
	tr {
    border-bottom: 1px solid #999;
	}
	th{
	width: 20%;
	text-align: left;
	padding: 32px 20px;
	}
	td{
	padding: 32px 20px;
	}
	}
	}
}
	
@media #{$pc}  {
}
@media #{$sp} {
}


//会社概要
#section06{
	position: relative;
	margin-bottom: 80px;
	h2{
	text-align: center;
	margin-bottom: 120px;
	}
	.container_in{
		background: #f9fdff;
		padding: 80px 0;
	.wrapper{
	margin: 0 auto;
	.section06_contents{
	width:100%;
    margin:0;
    padding:0;
    display: table;
	.section06_logo{
	width: 35%;
	  display: table-cell;
  vertical-align: middle;
	}
	.section06_table{
	width: 65%;
	border-left: 1px solid #999;
	table {
	tr {
	}
	th{
	width: 20%;
	text-align: left;
	padding:0 20px 0px;
	display: block;
	}
	td{
	padding-bottom: 20px;
	font-size: 14px;
	display: block;
	padding:0 40px 20px;
	ul{
	li{
	font-size: 14px;
	}
	}
	}
	}
	}
	}
	}
	}
}
	
@media #{$pc}  {
}
@media #{$sp} {
#section05{
margin-bottom: 40px;
	.wrapper{
	h2{
	text-align: center;
	}
	input[type="radio"]{display:none;}
.tab_area{font-size:0; margin:0 10px;}
.tab_area label{width:46%; margin:0 5px; display:inline-block; padding:12px 0; color:#999; background:#ddd; text-align:center; font-size:12px; cursor:pointer; transition:ease 0.2s opacity;}
.tab_area label:hover{opacity:1;}
.panel_area{background:#fff;}
.tab_panel{width:100%; padding:80px 0; display:none;}
.tab_panel p{font-size:14px; letter-spacing:1px; text-align:center;}
	table {
	tr {
    border-bottom: 1px solid #999;
	}
	th{
	width: 30%;
	text-align: left;
	padding: 32px 20px;
	}
	td{
	padding: 32px 20px;
	}
	}
	}
}
}
@media #{$pc}  {
}
@media #{$sp} {
}


//会社概要
#section06{
	position: relative;
	margin-bottom: 120px;
	h2{
	text-align: center;
	margin-bottom: 120px;
	}
	.container_in{
		background: #f9fdff;
		padding: 80px 0;
	.wrapper{
	
	.section06_contents{
	width:100%;
    margin:0;
    padding:0;
    display: table;
	.section06_logo{
	width: 35%;
	  display: table-cell;
  vertical-align: middle;
	}
	.section06_table{
	width: 65%;
	border-left: 1px solid #999;
	table {
	tr {
	}
	th{
	width: 20%;
	text-align: left;
	padding:0 20px 0px;
	display: block;
	}
	td{
	padding-bottom: 20px;
	font-size: 14px;
	display: block;
	padding:0 40px 20px;
	ul{
	li{
	font-size: 14px;
	}
	}
	}
	}
	}
	}
	}
	}
}

@media #{$pc}  {
}
@media #{$sp} {
#section06{
	position: relative;
	margin-bottom: 80px;
	h2{
	text-align: center;
	margin-bottom: 40px;
	}
	.container_in{
		background: #f9fdff;
		padding: 40px 0;
	.wrapper{
	.section06_contents{
	width:100%;
    margin:0;
    padding:0;
    display: table;
	.section06_logo{
	width: 40%;
	display: block;
  	vertical-align: middle;
	margin: 0 auto 40px auto;
	}
	.section06_table{
	width: 100%;
	border-left: none;
	table {
	tr {
	}
	th{
	width: 100%;
	text-align: left;
	padding:0;
	display: block;
	}
	td{
	padding-bottom: 20px;
	font-size: 14px;
	display: block;
	padding:0 0 20px;
	ul{
	li{
	font-size: 14px;
	}
	}
	}
	}
	}
	}
	}
	}
}
}

//お問い合わせ
#section07{
	position: relative;
	margin-bottom: 120px;
	h2{
	text-align: center;
	margin-bottom: 120px;
	}
	.container_in{
		background: #f9fdff;
		padding: 80px 0;
	.wrapper{
	margin: 0 auto;
	.section06_contents{
	width:100%;
    margin:0;
    padding:0;
    display: table;
	.section06_logo{
	width: 35%;
	  display: table-cell;
  vertical-align: middle;
	}
	.section06_table{
	width: 65%;
	border-left: 1px solid #999;
	table {
	tr {
	}
	th{
	width: 20%;
	text-align: left;
	padding:0 20px 0px;
	display: block;
	}
	td{
	padding-bottom: 20px;
	font-size: 14px;
	display: block;
	padding:0 40px 20px;
	ul{
	li{
	font-size: 14px;
	}
	}
	}
	}
	}
	}
	}
	}
}
	
@media #{$pc}  {
}
@media #{$sp} {
#section07{
	position: relative;
	margin-bottom: 120px;
	h2{
	text-align: center;
	margin-bottom: 40px;
	}
	.container_in{
		background: #f9fdff;
		padding: 80px 0;
	.wrapper{
	
	.section06_contents{
	width:100%;
    margin:0;
    padding:0;
    display: table;
	.section06_logo{
	width: 35%;
	  display: table-cell;
  vertical-align: middle;
	}
	.section06_table{
	width: 65%;
	border-left: 1px solid #999;
	table {
	tr {
	}
	th{
	width: 20%;
	text-align: left;
	padding:0 20px 0px;
	display: block;
	}
	td{
	padding-bottom: 20px;
	font-size: 14px;
	display: block;
	padding:0 40px 20px;
	ul{
	li{
	font-size: 14px;
	}
	}
	}
	}
	}
	}
	}
	}
}
}

/*=============== to_top ==============*/
#page-top {
	position: fixed;
	bottom:2%;
	right: 1%;
	z-index: 999999;
}
#page-top a:hover {
	text-decoration: none;
}

@media screen and (min-width: 768px) {
}

@media screen and (max-width: 767px) {
#page-top {
	right: 2%;
    bottom: 8%;
	width: 40px;
	height: 40px;
}
}

/*-----------------------------------------------------------------------------------*/
/*お問い合わせフォーム----------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------*/
#section07 p{
text-align: center;
margin-bottom: 40px;
}
#section07 .wrapper{
margin: 0 auto;
}
#section07 form {
  margin: 0 200px;
}
#section07 .contact_tel{
    border: 1px solid #999;
    margin: 0 auto 80px;
    display: block;
    width: 40%;
    text-align: center;
}
#section07 form table {
  width: 100%;
  margin-bottom: 20px;
}

#section07 form table th, #section07 form table td {
padding: 16px 0;
border-bottom: 1px solid #e5e5e5;
}
#section07 form table th {
  margin-bottom: 20px;
  vertical-align: middle;
}
#section07 form table td {
  margin-bottom: 20px;
}
#section07 form table th .required{
	background:#ff7d95;
	color: #fff;
	font-size: 12px;
	padding: 2px 4px;
	margin-left: 4px;
}
#section07 form input[type="text"], #section07 form input[type="tel"], #section07 form input[type="email"], #section07 form select, #section07 form textarea {
  font-size: 14px;
  width: 90%;
  margin: 0 auto;
  padding: 12px;
}
#section07 form textarea {
	height: 200px;
}
@media screen and (min-width:768px) and ( max-width:1023px) {
#section07 form {
  margin: 0;
}
}
@media screen and (max-width: 767px) {
  #section07 form {
    margin: 0 15px;
  }
  #section07 .contact_pnakuzu .pankuzu {
    margin-bottom: 60px;
  }
  
#section07 .contact_tel{
  border: 1px solid #999;
    margin: 0px auto 40px;
    display: block;
    width: auto;
    text-align: center;
	}
#section07 form table th{
border: none;
margin-bottom: 0;
padding: 0;
}
#section07 form table th, #section07 form table td {
display: block;
}
}

/*-----------------------------------------------------------------------------------*/
/*確認画面----------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------*/
#confirm {
	padding: 80px 0;
}
#confirm .wrapper{
	background: #fff;
	padding: 120px 0;
}
#confirm h3 {
	text-align: center;
}
#confirm p {
	text-align: center;
}
.sectin04_table {
	padding: 0 40px;
}
.form_table{
	padding: 0 40px;
	width: 80%;
	margin: 0 auto 20px auto;
}
.button input[type="button"]{
	border: 1px solid #999;
    background: #FFF;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    padding: 16px 80px;
    display: block;
    margin: 0 auto 20px auto;
    box-sizing: border-box;
}
#confirm table tr:first-child{
	border-top: 1px dotted #999;
	
}
#confirm table tr{
	border-bottom: 1px dotted #999;
	
}
#confirm table td a{
	background: #28658c;
    color: #fff;
    padding: 16px 40px;
}
#confirm table th,#confirm table td{
	padding: 30px 0;
	line-height: 1.7em;
}
#confirm table th{
	width: 30%
}
#confirm table th span{
	background: #999;
	color: #fff;
	font-weight: normal;
	margin-left: 6px;
	font-size: 12px;
	padding: 4px 8px;
}
#confirm table td{
	text-align: left;
	width: 70%;
}
#confirm table td a:before{
	background: url(../img/icn_pdf.png) #286d8c no-repeat;
	content: "";
    display: inline-block;
    background-size: 100%;
    background-position: 50%;
	vertical-align: middle;
	margin-right: 6px;
    width: 19px;
    height: 22px;
    color: #fff;
}

@media #{$pc}  {
}
@media #{$sp} {
#confirm {
    padding: 80px 10px;
}
#confirm .wrapper {
	padding: 0 0 20px;
}
#confirm p {
    text-align: left;
	padding: 0 20px;
}
#confirm h2 {
    color: #fff;
    background: #286d8c;
    font-size: 20px;
    padding: 1.8em;
    margin-bottom: 30px;
}
.sectin04_table {
	padding: 0;
}
.sectin04_table {
    padding: 0 20px;
}
#confirm table td a{
	background-color:transparent;
    color: #fff;
    padding: 0;
}
#confirm table td a:before{
	background: url(../img/icn_pdf.png) no-repeat;
	content: "";
    display: inline-block;
    background-size: 100%;
    background-position: 50%;
	vertical-align: middle;
	margin-right: 6px;
    width: 19px;
    height: 22px;
    color: #fff;
}
}

/*-----------------------------------------------------------------------------------*/
/*完了画面----------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------*/
#thanks {
	padding: 120px 0;
}
#thanks .wrapper{
	background: #fff;
	padding: 120px 0;
}
#thanks h3 {
	text-align: center;
}
#thanks p {
	text-align: center;
}


@media #{$pc}  {
}
@media #{$sp} {
#thanks {
    padding: 80px 10px;
}
#thanks .wrapper {
	padding: 0 0 20px;
}
#thanks p {
    text-align: left;
	padding: 0 20px;
}
#thanks h2 {
    color: #fff;
    background: #286d8c;
    font-size: 20px;
    padding: 1.8em;
    margin-bottom: 30px;
}
}

/*-----------------------------------------------------------------------------------*/
/*スライドショー----------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------*/
.slideshow {
  position: relative;
  width: 100%;
  padding-top: 50%;
  overflow: hidden;
  margin: 0 auto;
}
.slideshow .item {
  width: 100%;
  position: absolute;
  top: 0;
  display: none;
}
.in {
  -webkit-transform: scale(1);
  transform: scale(1);
  z-index: 2;
}
.out {
  -webkit-transform: scale(1.25);
  transform: scale(1.25);
  -webkit-transition: 4s;
  transition: 4s;
  z-index: 1;
 }
.title {
  text-align: center;
  color: rgba(102, 102, 102, 0.70);
  margin: 20px 0 15px 0;
}
@media #{$pc}  {
}
@media #{$sp} {
.slideshow {
  position: relative;
  width: 100%;
  padding-top: 132%;
  overflow: hidden;
  margin: 0 auto;
}
}



//会社概要
#section08{
	margin-bottom: 120px;
}
@media #{$pc}  {
}
@media #{$sp} {
#section08{
	margin-bottom: 60px;
}
}
/*# sourceMappingURL=style.css.map */ 