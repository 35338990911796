@charset "utf-8";

//clearfix-----------------------------------------------------------------------------------------------------//
%clearfix{ 
  overflow: hidden;
  *zoom: 1;
} 
%clearfix02{
	  *zoom: 1;
		&:after {
		  content: "";
		  display: table;
		  clear: both;
		}
}

//ボックスリンクを全体に広げる----------------------------------------------
.box_link{
	position: relative;
	a{
		position: absolute;
		top:0;
		left: 0;
		width:100%;
		height: 100%;
	}
}

.flex_box{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.flex_box_center{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

//入れ子の画像をmax-width: 100%にする。------------------------------------------------------------------------//
@mixin img100($width:100%){
	width: $width;
	img{max-width: 100%;}
}

//	アフターの画像と位置を調整するミックスイン-------------------------------------------------------------------------------
@mixin afterImg($posStatic,$vertical,$horizontal,$width,$height,$uri){
	position: relative;
		a{
			position: absolute;
			top:0;
			left: 0;
			width:100%;
			height: 100%;
		}
		&:after{
			position: absolute;
			content:'';
			display: block;
			width: $width;
			height:$height;
			background-image: url(#{$uri});
			background-size:100%;
			background-repeat: no-repeat;
				@if $posStatic == 'TL'{
								top:$vertical;
								left:$horizontal;
				}
				@else if $posStatic == 'TR'{
								top:$vertical;
								right:$horizontal;
				}
				@else if $posStatic == 'BL'{
								Bottom:$vertical;
								left:$horizontal;
				}
				@else if $posStatic == 'BR'{
								Bottom:$vertical;
								right:$horizontal;
				}
	}//&:after{
}


// 三角ジェネレーターmixin-----------------------------------------------------------------------------------//
@mixin triangle($color: #000, $direction: top, $height: 10px, $width: 10px, $position: null, $bd_color: null) {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    z-index: 2;
    border-style: solid;
    @if $direction == "top" {
      top: -$height;
      left: 0;
      border-width: 0 $width $height $width;
      border-color: transparent transparent $color transparent;
    }
    @else if $direction == "right" {
      top: 0;
      right: -$height;
      border-width: $width 0 $width $height;
      border-color: transparent transparent transparent $color;
    }
    @else if $direction == "bottom" {
      bottom: -$height;
      left: 0;
      border-width: $height $width 0 $width;
      border-color: $color transparent transparent transparent;
    }
    @else if $direction == "left" {
      top: 0;
      left: -$height;
      border-width: $width $height $width 0;
      border-color: transparent #{$color} transparent transparent;
    }
    @if length($direction) == 2 {
      $top-or-bottom: nth($direction, 1);
      $left-or-right: nth($direction, 2);
      @if $top-or-bottom == "top" {
        @if $left-or-right == "left" {
          border-width: $height $height 0 0;
          border-color: $color transparent transparent transparent;
        }
        @else if $left-or-right == "right" {
          border-width: 0 $height $height 0;
          border-color: transparent $color transparent transparent;
        }
      }
      @else if $top-or-bottom == "bottom" {
        @if $left-or-right == "left" {
          border-width: $height 0 0 $height;
          border-color: transparent transparent transparent $color;
        }
        @else if $left-or-right == "right" {
          border-width: 0 0 $height $height;
          border-color: transparent transparent $color transparent;
        }
      }
    }
    @if $position == "center" {
      left: 50%;
      margin-left: - $width;
    }
    @else if $position == "middle" {
      top: 50%;
      margin-top: - $height;
    }
    @else if $position AND ($direction == "left" OR $direction == "right"){
      top: $position;
    }
    @else if $position AND ($direction == "top" OR $direction == "bottom"){
      left: $position;
    }
  }
  @if $bd_color {
    &:after {
      $bd_height: $height + 1px;
      content: "";
      position: absolute;
      z-index: 1;
      border-style: solid;
      @if $direction == "top" {
        top: -$bd_height;
        left: 0;
        border-width: 0 $width $height $width;
        border-color: transparent transparent $bd_color transparent;
      }
      @else if $direction == "right" {
        top: 0;
        right: -$bd_height;
        border-width: $width 0 $width $height;
        border-color: transparent transparent transparent $bd_color;
      }
      @else if $direction == "bottom" {
        bottom: -$bd_height;
        left: 0;
        border-width: $height $width 0 $width;
        border-color: $bd_color transparent transparent transparent;
      }
      @else if $direction == "left" {
        top: 0;
        left: -$bd_height;
        border-width: $width $height $width 0;
        border-color: transparent #{$bd_color} transparent transparent;
      }
      @if length($direction) == 2 {
        $top-or-bottom: nth($direction, 1);
        $left-or-right: nth($direction, 2);
        @if $top-or-bottom == "top" {
          @if $left-or-right == "left" {
            border-width: $height $height 0 0;
            border-color: $bd_color transparent transparent transparent;
          }
          @else if $left-or-right == "right" {
            border-width: 0 $height $height 0;
            border-color: transparent $bd_color transparent transparent;
          }
        }
        @else if $top-or-bottom == "bottom" {
          @if $left-or-right == "left" {
            border-width: $height 0 0 $height;
            border-color: transparent transparent transparent $bd_color;
          }
          @else if $left-or-right == "right" {
            border-width: 0 0 $height $height;
            border-color: transparent transparent $bd_color transparent;
          }
        }
      }
      @if $position == "center" {
        left: 50%;
        margin-left: - $width;
      }
      @else if $position == "middle" {
        top: 50%;
        margin-top: - $height;
      }
      @else if $position AND ($direction == "left" OR $direction == "right"){
        top: $position;
      }
      @else if $position AND ($direction == "top" OR $direction == "bottom"){
        left: $position;
      }
    }
  }
}