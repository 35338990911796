@charset "UTF-8";
/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

.box_link {
  position: relative;
}

.box_link a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.flex_box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex_box_center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

/*-----------------------------------------------------------------------------------*/
/*共通部分---------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------*/
body {
  font-size: 18px;
  color: #1f1f1f;
  font-family: "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", sans-serif;
  line-height: 1.6;
  text-size-adjust: 100%;
}

body a {
  color: #416429 　;
}

html, body {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: bordr-box;
  -o-box-sizing: boder-box;
  -ms-box-sizing: border-box;
}

body {
  font-family: 'Roboto', "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", sans-serif;
  -webkit-text-size-adjust: none;
  letter-spacing: 0.05em;
}

.mincho {
  font-family: "游明朝",YuMincho,"ヒラギノ明朝 Pro W3","Hiragino Mincho Pro","HG明朝E","MS P明朝","MS 明朝",serif;
}

p, li {
  text-align: justify;
  text-justify: inter-ideograph;
  line-height: 1.7em;
  font-size: 16px;
}

a {
  text-decoration: none;
  color: #416429;
}

a:hover {
  text-decoration: none;
}

a[target="_blank"]:after {
  content: "";
  padding-left: 13px;
  background: url(../img/icn_outlink.png) no-repeat center left;
  background-size: 100%;
  width: 12px;
  height: 10px;
  margin-left: 4px;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

li {
  list-style-type: none;
}

h2, h3 {
  line-height: 1.6em;
}

h2 {
  margin-bottom: 40px;
}

input[type="text"], input[type="tel"], input[type="email"], select, textarea {
  display: block;
  border: none;
  background: #f4f4f4;
  padding: 10px 24px;
  border-radius: none;
  -webkit-appearance: none;
}

input[type="submit"], input[type="button"] {
  border-radius: 0;
  -webkit-appearance: none;
  border: none;
}

input[type="button"] {
  display: inline-block;
  padding: 18px 50px;
  border: 1px solid #707070;
  background: #FFF;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  -webkit-appearance: none;
  border-radius: 0;
}

input[type="submit"] {
  background-position: center;
  background-color: #4d94ae;
  color: #fff;
  font-weight: bold;
  padding: 20px 80px;
  display: block;
  margin: 0 auto;
}

input[type="submit"]:hover {
  opacity: 0.8;
}

input[type="radio"].radio_design {
  display: none;
}

.clearfix::after {
  content: " ";
  display: block;
  clear: both;
}

.center {
  text-align: center;
}

.attention {
  font-size: 12px;
}

@media screen and (min-width: 768px) {
  body {
    font-size: 14px;
    line-height: 1.7em;
  }
  p, li {
    text-align: justify;
    text-justify: inter-ideograph;
    line-height: 1.7em;
    font-size: 16px;
  }
  input[type="text"], input[type="tel"], input[type="email"], select, textarea {
    font-size: 14px;
  }
  input[type="radio"] + .radio:before {
    display: inline-block;
    content: "";
    margin-right: 10px;
    width: 1.8em;
    height: 1.8em;
    border: 1px solid #9e9e9e;
    background-color: #fff;
    vertical-align: middle;
    cursor: pointer;
  }
  input[type="radio"] + .radio {
    margin-right: 40px;
  }
  input[type="radio"]:checked + .radio:before {
    background-color: #960101;
  }
}

@media screen and (max-width: 767px) {
  body {
    font-size: 14px;
    line-height: 1.6em;
  }
  p, li {
    font-size: 14px;
  }
  input[type="text"], input[type="tel"], input[type="email"], select, textarea {
    font-size: 16px;
  }
  input[type="radio"] + .radio:before {
    display: inline-block;
    content: "";
    margin-right: 10px;
    width: 1em;
    height: 1em;
    border: 1px solid #9e9e9e;
    background-color: #fff;
    vertical-align: middle;
    cursor: pointer;
  }
  input[type="radio"] + .radio {
    margin-right: 10px;
    display: inline-block;
  }
  input[type="radio"]:checked + .radio:before {
    background-color: #960101;
  }
}

/*-----PC・SP切り替え-----*/
@media screen and (min-width: 768px) {
  .sp {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .pc {
    display: none !important;
  }
}

.head, .footer {
  width: 100%;
}

.container {
  max-width: 100%;
}

.container_in {
  margin: 0 80px;
}

.wrapper {
  max-width: 1024px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .container_in {
    margin: 0 4%;
  }
}

@media screen and (min-width: 768px) {
  .container_in {
    margin: 0 80px;
  }
  .wrapper {
    margin: 0 4%;
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    padding: 0 20px;
  }
  .container_in {
    margin: 0 4%;
  }
}

.btn01 {
  display: block;
  max-width: 200px;
  margin: 0 auto;
}

.btn01 a {
  text-align: center;
  display: block;
  background: #416429;
  color: #fff;
  padding: 10px 40px;
  position: relative;
}

.btn01 a:after {
  content: "";
  position: absolute;
  background: url(../img/icn_arrow02.png) no-repeat center left;
  background-size: 100%;
  width: 5px;
  height: 9px;
  margin-left: 4px;
  top: 20px;
  right: 16px;
  transition: .3s ease-in-out;
}

.btn01 a:hover:after {
  top: 20px;
  right: 8px;
}

.btn02 {
  display: block;
  max-width: 200px;
  margin: 0 auto;
}

.btn02 a {
  text-align: center;
  display: block;
  border: 2px solid #fff;
  color: #fff;
  padding: 10px 40px;
  position: relative;
}

.btn02 a:after {
  content: "";
  position: absolute;
  background: url(../img/icn_arrow02.png) no-repeat center left;
  background-size: 100%;
  width: 5px;
  height: 9px;
  margin-left: 4px;
  top: 20px;
  right: 16px;
  transition: .3s ease-in-out;
}

.btn02 a:hover:after {
  top: 20px;
  right: 8px;
}

body {
  position: relative;
}

.body_line {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -10;
}

.body_line .container_in {
  height: 100%;
  margin: 0 auto;
  position: relative;
}

.body_line .container_in span {
  background-color: rgba(238, 238, 238, 0.5);
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 1px;
}

.body_line .container_in span:nth-child(1) {
  left: 2%;
}

.body_line .container_in span:nth-child(2) {
  left: 33%;
}

.body_line .container_in span:nth-child(3) {
  left: 66%;
}

.body_line .container_in span:nth-child(4) {
  left: 98%;
}

/*=============== header ==============*/
header {
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999;
  background: #fff;
  transition: 1.0s;
  text-decoration: none;
}

header a {
  transition: 1.0s;
  text-decoration: none;
  color: #000;
}

header .container .wrapper .header_bar {
  height: 10px;
  background: #296eb2;
}

header .container_in ul li {
  display: inline-block;
  margin-right: 16px;
  font-weight: bold;
}

header .container_in #global_nav {
  padding: 30px 0;
  width: 60%;
  display: inline-block;
}

header .container_in #global_nav #global_nav_contact a:before {
  content: "";
  display: inline-block;
  vertical-align: text-bottom;
  background: url(../img/header_icn_mail.png) no-repeat center left;
  background-size: 100%;
  width: 25px;
  height: 18px;
  margin-right: 4px;
}

header .header_rental_btn {
  position: absolute;
  right: 0;
}

header .header_content {
  position: relative;
  padding: 20px 0;
  float: left;
  width: 18%;
}

header .header_content .header_logo {
  float: left;
  width: 100%;
  font-weight: bold;
}

header .header_content .header_logo p {
  font-size: 32px;
}

header .inner {
  position: relative;
  padding: 30px 0;
}

#global_nav ul {
  width: 100%;
  list-style: none;
  font-size: 16px;
  float: left;
}

#global_nav ul li {
  float: left;
}

#global_nav ul li a {
  padding: 0 8px;
  font-size: 16px;
}

#global_nav ul li a img {
  vertical-align: middle;
}

.header_tel {
  float: right;
  padding: 30px 0;
  margin-right: 20px;
  width: 18%;
}

/* Toggle Button */
#nav_toggle {
  display: none;
  position: absolute;
  right: 12px;
  top: 40px;
  width: 34px;
  height: 36px;
  cursor: pointer;
  z-index: 101;
}

#nav_toggle div {
  position: relative;
}

#nav_toggle span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: #39afba;
  left: 0;
  -webkit-transition: .35s ease-in-out;
  -moz-transition: .35s ease-in-out;
  transition: .35s ease-in-out;
}

#nav_toggle span:nth-child(1) {
  top: 0;
}

#nav_toggle span:nth-child(2) {
  top: 11px;
}

#nav_toggle span:nth-child(3) {
  top: 22px;
}

@media screen and (min-width: 768px) {
  header ul li a {
    display: block;
    position: relative;
    text-decoration: none;
  }
}

@media screen and (max-width: 767px) {
  header {
    width: 100%;
    padding: 0;
    top: 0;
    position: fixed;
    margin-top: 0;
  }
  header .container .header_bar {
    background: #000;
    color: #fff;
    font-size: 10px;
    padding: 2px 0;
  }
  header .fixed {
    padding-top: 0;
    background: transparent;
  }
  header .header_content {
    padding: 10px 0;
  }
  header .header_content .header_logo p {
    font-size: 16px;
  }
  #mobile-head {
    background: #fff;
    width: 100%;
    z-index: 999;
    position: relative;
  }
  header .container_in #global_nav {
    position: absolute;
    /* 開いてないときは画面外に配置 */
    top: -714px;
    left: 0;
    background: #f6f6f6;
    width: 100%;
    text-align: center;
    padding: 10px 0;
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    width: 100%;
  }
  .header_tel {
    float: right;
    padding: 12px 0 0;
    margin-right: 50px;
    width: 38%;
  }
  #global_nav ul {
    list-style: none;
    position: static;
    right: 0;
    bottom: 0;
    font-size: 14px;
    float: none;
  }
  #global_nav ul li {
    float: none;
    position: static;
    display: block;
  }
  header #global_nav ul li a, header.fixed #global_nav ul li a {
    width: 100%;
    display: block;
    padding: 16px 20px;
    font-size: 13px;
  }
  #nav_toggle {
    display: block;
    right: 4%;
    top: 10px;
  }
  /* #nav_toggle 切り替えアニメーション */
  .open #nav_toggle span:nth-child(1) {
    top: 11px;
    -webkit-transform: rotate(315deg);
    -moz-transform: rotate(315deg);
    transform: rotate(315deg);
  }
  .open #nav_toggle span:nth-child(2) {
    width: 0;
    left: 50%;
  }
  .open #nav_toggle span:nth-child(3) {
    top: 11px;
    -webkit-transform: rotate(-315deg);
    -moz-transform: rotate(-315deg);
    transform: rotate(-315deg);
  }
  /* #global_nav スライドアニメーション */
  .open #global_nav {
    /* #global_nav top + #mobile-head height */
    -moz-transform: translateY(756px);
    -webkit-transform: translateY(756px);
    transform: translateY(756px);
  }
}

/*-----------------------------------------------------------------------------------*/
/*.mainContents----------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------*/
/*.footer-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------*/
footer .container_in {
  background: #f0f0f0;
  padding: 20px;
  color: #1f1f1f;
  margin-bottom: 80px;
}

footer .footer_content {
  margin-bottom: 80px;
}

footer .footer_content .compnay_name {
  font-size: 24px;
}

footer .copyright {
  font-size: 12px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  footer .container_in {
    background: #f0f0f0;
    padding: 20px;
    color: #1f1f1f;
    margin-bottom: 20px;
  }
  footer .footer_content {
    margin-bottom: 80px;
  }
  footer .footer_content .compnay_name {
    font-size: 24px;
  }
  footer .copyright {
    font-size: 10px;
    text-align: center;
  }
}

/*-----------------------------------------------------------------------------------*/
/*トップページ----------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------*/
#mv {
  position: relative;
  margin-top: 89px;
  margin-bottom: 80px;
}

#mv .mv_catch {
  position: absolute;
  width: 70%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#mv_point {
  position: relative;
  margin-bottom: 10%;
}

#mv_point .mv_point_list {
  position: absolute;
  width: 70%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#mv_point .mv_point_list ul {
  font-size: 0;
  text-align: center;
}

#mv_point .mv_point_list ul li {
  display: inline-block;
  font-size: 12px;
  padding: 0 10px;
  width: 20%;
}

@media screen and (max-width: 767px) {
  #mv {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  #mv .mv_catch {
    position: absolute;
    width: 70%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -150%);
    -moz-transform: translate(-50%, -150%);
    -ms-transform: translate(-50%, -150%);
    -o-transform: translate(-50%, -150%);
    transform: translate(-50%, -150%);
  }
  #mv_point {
    position: relative;
    margin-bottom: 10%;
  }
  #mv_point .mv_point_list {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -110%);
    -moz-transform: translate(-50%, -110%);
    -ms-transform: translate(-50%, -110%);
    -o-transform: translate(-50%, -110%);
    transform: translate(-50%, -110%);
  }
  #mv_point .mv_point_list ul li {
    display: inline-block;
    font-size: 12px;
    padding: 0 10px;
    width: 40%;
  }
}

#section01 {
  position: relative;
  margin-bottom: 220px;
}

#section01 .wrapper {
  position: relative;
  z-index: 999;
  margin: 0 auto;
}

#section01 .container_in {
  position: relative;
  top: 0;
}

#section01 .container_in .section01_bg {
  position: absolute;
  right: 0;
  top: -220px;
  width: 70%;
  padding: 140px 0;
  background-image: url(../img/section01_bg.jpg);
  background-size: 15px;
  background-position: 50% 50%;
}

@media screen and (max-width: 767px) {
  #section01 {
    position: relative;
    margin-bottom: 120px;
  }
  #section01 .wrapper {
    position: relative;
    z-index: 999;
  }
  #section01 .container_in {
    position: relative;
    top: 0;
  }
  #section01 .container_in .section01_bg {
    position: absolute;
    right: 0;
    top: -220px;
    width: 70%;
    padding: 140px 0;
    background-image: url(../img/section01_bg.jpg);
    background-size: 15px;
    background-position: 50% 50%;
  }
}

#section02 {
  position: relative;
  margin-bottom: 120px;
}

#section02 h2 {
  text-align: center;
  margin-bottom: 120px;
}

#section02 .container_in {
  background: #f9fdff;
  padding: 80px 0;
}

#section02 .container_in .section02_list {
  width: 92%;
  margin: 0 auto;
}

#section02 .container_in .section02_list ul li {
  width: 30%;
  margin-right: 5%;
  float: left;
  border: 1px solid #999;
  box-sizing: border-box;
  padding: 80px 2% 2%;
  position: relative;
}

#section02 .container_in .section02_list ul li:last-child {
  margin-right: 0;
}

#section02 .container_in .section02_list ul li h3 {
  text-align: center;
  margin-bottom: 20px;
}

#section02 .container_in .section02_list ul li img {
  display: block;
  margin: 0 auto 20px;
}

#section02 .container_in .section02_list ul li .section02_list_num {
  position: absolute;
  top: -45px;
  right: 0;
  left: 0;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  #section02 {
    position: relative;
    margin-bottom: 120px;
  }
  #section02 h2 {
    text-align: center;
    margin-bottom: 120px;
  }
  #section02 .container_in {
    background: #f9fdff;
    padding: 80px 0;
    margin: 0;
  }
  #section02 .container_in .section02_list {
    width: 92%;
    margin: 0 auto;
  }
  #section02 .container_in .section02_list ul li {
    width: 30%;
    margin-right: 5%;
    float: left;
    border: 1px solid #999;
    box-sizing: border-box;
    padding: 80px 2% 2%;
    position: relative;
  }
  #section02 .container_in .section02_list ul li:last-child {
    margin-right: 0;
  }
  #section02 .container_in .section02_list ul li h3 {
    text-align: center;
    margin-bottom: 20px;
  }
  #section02 .container_in .section02_list ul li img {
    display: block;
    margin: 0 auto 20px;
  }
  #section02 .container_in .section02_list ul li .section02_list_num {
    position: absolute;
    top: -45px;
    right: 0;
    left: 0;
  }
}

@media screen and (max-width: 767px) {
  #section02 {
    position: relative;
    margin-bottom: 40px;
  }
  #section02 h2 {
    text-align: center;
    margin-bottom: 120px;
  }
  #section02 .container_in {
    background: #f9fdff;
    padding: 40px 0;
  }
  #section02 .container_in .section02_list {
    width: 92%;
    margin: 0 auto;
  }
  #section02 .container_in .section02_list ul li {
    width: 100%;
    margin-right: 0;
    margin-bottom: 80px;
    float: none;
    border: 1px solid #999;
    box-sizing: border-box;
    padding: 80px 4% 4%;
    position: relative;
  }
  #section02 .container_in .section02_list ul li:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
  #section02 .container_in .section02_list ul li h3 {
    text-align: center;
    margin-bottom: 20px;
  }
  #section02 .container_in .section02_list ul li img {
    display: block;
    margin: 0 auto 20px;
  }
  #section02 .container_in .section02_list ul li .section02_list_num {
    position: absolute;
    top: -45px;
    right: 0;
    left: 0;
  }
}

#section03 {
  position: relative;
  margin-bottom: 220px;
}

#section03 h2 {
  text-align: center;
  margin-bottom: 120px;
}

#section03 .container_in .wrapper {
  margin: 0 auto;
}

#section03 .container_in .wrapper .section03_list ul li {
  margin-bottom: 80px;
}

#section03 .container_in .wrapper .section03_list ul li h3 {
  border-bottom: 1px solid #000;
  display: inline-block;
  font-size: 20px;
  margin-bottom: 20px;
}

#section03 .container_in .wrapper .section03_list ul li .section03_txt {
  font-size: 14px;
}

#section03 .container_in .wrapper .section03_list ul li .section03_name {
  font-size: 14px;
}

#section03 .container_in .wrapper .section03_list ul li .section03_name span {
  font-weight: bold;
  margin-bottom: 12px;
}

#section03 .container_in .wrapper .section03_list ul li:nth-child(odd) .section03_list_01 {
  width: 30%;
  background: #39afba;
  position: relative;
  float: left;
}

#section03 .container_in .wrapper .section03_list ul li:nth-child(odd) .section03_list_01 .section03_photo {
  position: relative;
  top: -30px;
  right: -30px;
}

#section03 .container_in .wrapper .section03_list ul li:nth-child(odd) .section03_list_02 {
  width: 60%;
  float: right;
  border: 1px solid #999;
  padding: 2%;
  background-image: url(../img/section03_bg.png);
  background-size: 7px;
  background-position: 50% 50%;
}

#section03 .container_in .wrapper .section03_list ul li:nth-child(even) .section03_list_02 {
  width: 30%;
  background: #39afba;
  position: relative;
  float: right;
}

#section03 .container_in .wrapper .section03_list ul li:nth-child(even) .section03_list_02 .section03_photo {
  position: relative;
  top: -30px;
  right: -30px;
}

#section03 .container_in .wrapper .section03_list ul li:nth-child(even) .section03_list_01 {
  width: 60%;
  float: left;
  border: 1px solid #999;
  padding: 2%;
  background-image: url(../img/section03_bg.png);
  background-size: 7px;
  background-position: 50% 50%;
}

@media screen and (max-width: 767px) {
  #section03 {
    position: relative;
    margin-bottom: 0;
  }
  #section03 h2 {
    text-align: center;
    margin-bottom: 80px;
  }
  #section03 .container_in .wrapper .section03_list ul li {
    margin-bottom: 80px;
  }
  #section03 .container_in .wrapper .section03_list ul li h3 {
    border-bottom: 1px solid #000;
    display: inline-block;
    font-size: 20px;
    margin-bottom: 20px;
  }
  #section03 .container_in .wrapper .section03_list ul li .section03_txt {
    font-size: 14px;
  }
  #section03 .container_in .wrapper .section03_list ul li .section03_name {
    font-size: 14px;
  }
  #section03 .container_in .wrapper .section03_list ul li .section03_name span {
    font-weight: bold;
    margin-bottom: 12px;
  }
  #section03 .container_in .wrapper .section03_list ul li:nth-child(odd) .section03_list_01 {
    width: 90%;
    margin-bottom: 20px;
    background: #39afba;
    position: relative;
    float: none;
  }
  #section03 .container_in .wrapper .section03_list ul li:nth-child(odd) .section03_list_01 .section03_photo {
    position: relative;
    top: -30px;
    right: -30px;
  }
  #section03 .container_in .wrapper .section03_list ul li:nth-child(odd) .section03_list_02 {
    width: 80%;
    float: none;
    border: 1px solid #999;
    padding: 10%;
    background-image: url(../img/section03_bg.png);
    background-size: 7px;
    background-position: 50% 50%;
  }
  #section03 .container_in .wrapper .section03_list ul li:nth-child(even) .section03_list_02 {
    width: 90%;
    margin-bottom: 20px;
    background: #39afba;
    position: relative;
    float: none;
  }
  #section03 .container_in .wrapper .section03_list ul li:nth-child(even) .section03_list_02 .section03_photo {
    position: relative;
    top: -30px;
    right: -30px;
  }
  #section03 .container_in .wrapper .section03_list ul li:nth-child(even) .section03_list_01 {
    width: 80%;
    float: none;
    border: 1px solid #999;
    padding: 10%;
    background-image: url(../img/section03_bg.png);
    background-size: 7px;
    background-position: 50% 50%;
  }
}

#section04 {
  margin-bottom: 80px;
}

#section04 .container_in {
  padding: 60px 0;
}

#section04 .container_in .wrapper {
  margin: 0 auto;
}

#section04 .container_in .wrapper section {
  width: 60%;
}

#section04 .container_in:nth-child(even) .wrapper section {
  float: right;
}

#section04 .section04_01 {
  background-image: url(../img/section04_bg01.jpg);
  background-size: cover;
  background-position: 50% 50%;
}

#section04 .section04_02 {
  background-image: url(../img/section04_bg02.jpg);
  background-size: cover;
  background-position: 50% 50%;
}

#section04 .section04_03 {
  background-image: url(../img/section04_bg03.jpg);
  background-size: cover;
  background-position: 50% 50%;
}

#section04 .section04_04 {
  background-image: url(../img/section04_bg04.jpg);
  background-size: cover;
  background-position: 50% 50%;
}

#section04 .section04_05 {
  background-image: url(../img/section04_bg05.jpg);
  background-size: cover;
  background-position: 50% 50%;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  #section04 {
    margin-bottom: 80px;
  }
  #section04 .container_in {
    padding: 60px 0;
  }
  #section04 .container_in .wrapper section {
    width: 60%;
  }
  #section04 .container_in:nth-child(even) .wrapper section {
    float: right;
  }
  #section04 .section04_01 {
    background-image: url(../img/section04_bg01.jpg);
    background-size: cover;
    background-position: 50% 50%;
  }
  #section04 .section04_02 {
    background-image: url(../img/section04_bg02.jpg);
    background-size: cover;
    background-position: 50% 50%;
  }
  #section04 .section04_03 {
    background-image: url(../img/section04_bg03.jpg);
    background-size: cover;
    background-position: 50% 50%;
  }
  #section04 .section04_04 {
    background-image: url(../img/section04_bg04.jpg);
    background-size: cover;
    background-position: 50% 50%;
  }
  #section04 .section04_05 {
    background-image: url(../img/section04_bg05.jpg);
    background-size: cover;
    background-position: 50% 50%;
  }
}

@media screen and (max-width: 767px) {
  #section04 {
    margin-bottom: 40px;
  }
  #section04 .container_in {
    padding: 140px 0 60px 0;
    margin: 0 4% !important;
  }
  #section04 .container_in .wrapper section {
    width: 100%;
  }
  #section04 .container_in:nth-child(even) .wrapper section h2 {
    text-align: right;
  }
  #section04 .section04_01 {
    background-size: 150%;
    background-repeat: no-repeat;
    background-position: 100% 0%;
    background-color: #cfd0cf;
  }
  #section04 .section04_02 {
    background-size: 150%;
    background-repeat: no-repeat;
    background-position: 0% 0%;
    background-color: #ebf4fc;
  }
  #section04 .section04_03 {
    background-size: 150%;
    background-repeat: no-repeat;
    background-position: 100% 0%;
    background-color: #f6f7fa;
  }
  #section04 .section04_04 {
    background-size: 150%;
    background-repeat: no-repeat;
    background-position: 0% 0%;
    background-color: #fff;
  }
  #section04 .section04_05 {
    background-size: 150%;
    background-repeat: no-repeat;
    background-position: 100% 0%;
    background-color: #f0f0f0;
  }
}

#cta {
  margin-bottom: 120px;
}

#cta .wrapper {
  background: #39afba;
  padding: 4% 0;
  margin: 0 auto;
}

#cta .wrapper h2 {
  text-align: center;
}

#cta .wrapper ul {
  padding: 4%;
}

#cta .wrapper ul li {
  width: 48%;
  margin-right: 4%;
  float: left;
}

#cta .wrapper ul li:last-child {
  margin-right: 0;
}

@media screen and (max-width: 767px) {
  #cta {
    margin-bottom: 80px;
  }
  #cta .wrapper {
    background: #39afba;
    padding: 14% 0 0%;
  }
  #cta .wrapper h2 {
    text-align: center;
  }
  #cta .wrapper ul {
    padding: 4%;
  }
  #cta .wrapper ul li {
    width: 100%;
    margin-right: 0;
    margin-bottom: 4%;
    float: none;
  }
  #cta .wrapper ul li:last-child {
    margin-right: 0;
  }
}

#section05 {
  margin-bottom: 120px;
}

#section05 .wrapper {
  margin: 0 auto;
}

#section05 .wrapper h2 {
  text-align: center;
  margin-bottom: 80px;
}

#section05 .wrapper input[type="radio"] {
  display: none;
}

#section05 .wrapper .tab_area {
  font-size: 0;
  margin: 0 10px;
}

#section05 .wrapper .tab_area label {
  width: 25%;
  margin: 0 5px;
  display: inline-block;
  padding: 12px 0;
  color: #999;
  background: #ddd;
  text-align: center;
  font-size: 13px;
  cursor: pointer;
  transition: ease 0.2s opacity;
}

#section05 .wrapper .tab_area label:hover {
  opacity: 0.5;
}

#section05 .wrapper .panel_area {
  background: #fff;
}

#section05 .wrapper .tab_panel {
  width: 100%;
  padding: 80px 0;
  display: none;
}

#section05 .wrapper .tab_panel p {
  font-size: 14px;
  letter-spacing: 1px;
  text-align: center;
}

#section05 .wrapper #tab1:checked ~ .tab_area .tab1_label {
  background: #39afba;
  color: #fff;
  position: relative;
}

#section05 .wrapper #tab1:checked ~ .tab_area .tab1_label:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -15px;
  border: 15px solid transparent;
  border-top: 15px solid #39afba;
}

#section05 .wrapper #tab1:checked ~ .panel_area #panel1 {
  display: block;
}

#section05 .wrapper #tab2:checked ~ .tab_area .tab2_label {
  background: #39afba;
  color: #fff;
  position: relative;
}

#section05 .wrapper #tab2:checked ~ .tab_area .tab2_label:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -15px;
  border: 15px solid transparent;
  border-top: 15px solid #39afba;
}

#section05 .wrapper #tab2:checked ~ .panel_area #panel2 {
  display: block;
}

#section05 .wrapper table tr {
  border-bottom: 1px solid #999;
}

#section05 .wrapper table th {
  width: 20%;
  text-align: left;
  padding: 32px 20px;
}

#section05 .wrapper table td {
  padding: 32px 20px;
}

#section06 {
  position: relative;
  margin-bottom: 80px;
}

#section06 h2 {
  text-align: center;
  margin-bottom: 120px;
}

#section06 .container_in {
  background: #f9fdff;
  padding: 80px 0;
}

#section06 .container_in .wrapper {
  margin: 0 auto;
}

#section06 .container_in .wrapper .section06_contents {
  width: 100%;
  margin: 0;
  padding: 0;
  display: table;
}

#section06 .container_in .wrapper .section06_contents .section06_logo {
  width: 35%;
  display: table-cell;
  vertical-align: middle;
}

#section06 .container_in .wrapper .section06_contents .section06_table {
  width: 65%;
  border-left: 1px solid #999;
}

#section06 .container_in .wrapper .section06_contents .section06_table table th {
  width: 20%;
  text-align: left;
  padding: 0 20px 0px;
  display: block;
}

#section06 .container_in .wrapper .section06_contents .section06_table table td {
  padding-bottom: 20px;
  font-size: 14px;
  display: block;
  padding: 0 40px 20px;
}

#section06 .container_in .wrapper .section06_contents .section06_table table td ul li {
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  #section05 {
    margin-bottom: 40px;
  }
  #section05 .wrapper h2 {
    text-align: center;
  }
  #section05 .wrapper input[type="radio"] {
    display: none;
  }
  #section05 .wrapper .tab_area {
    font-size: 0;
    margin: 0 10px;
  }
  #section05 .wrapper .tab_area label {
    width: 46%;
    margin: 0 5px;
    display: inline-block;
    padding: 12px 0;
    color: #999;
    background: #ddd;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
    transition: ease 0.2s opacity;
  }
  #section05 .wrapper .tab_area label:hover {
    opacity: 1;
  }
  #section05 .wrapper .panel_area {
    background: #fff;
  }
  #section05 .wrapper .tab_panel {
    width: 100%;
    padding: 80px 0;
    display: none;
  }
  #section05 .wrapper .tab_panel p {
    font-size: 14px;
    letter-spacing: 1px;
    text-align: center;
  }
  #section05 .wrapper table tr {
    border-bottom: 1px solid #999;
  }
  #section05 .wrapper table th {
    width: 30%;
    text-align: left;
    padding: 32px 20px;
  }
  #section05 .wrapper table td {
    padding: 32px 20px;
  }
}

#section06 {
  position: relative;
  margin-bottom: 120px;
}

#section06 h2 {
  text-align: center;
  margin-bottom: 120px;
}

#section06 .container_in {
  background: #f9fdff;
  padding: 80px 0;
}

#section06 .container_in .wrapper .section06_contents {
  width: 100%;
  margin: 0;
  padding: 0;
  display: table;
}

#section06 .container_in .wrapper .section06_contents .section06_logo {
  width: 35%;
  display: table-cell;
  vertical-align: middle;
}

#section06 .container_in .wrapper .section06_contents .section06_table {
  width: 65%;
  border-left: 1px solid #999;
}

#section06 .container_in .wrapper .section06_contents .section06_table table th {
  width: 20%;
  text-align: left;
  padding: 0 20px 0px;
  display: block;
}

#section06 .container_in .wrapper .section06_contents .section06_table table td {
  padding-bottom: 20px;
  font-size: 14px;
  display: block;
  padding: 0 40px 20px;
}

#section06 .container_in .wrapper .section06_contents .section06_table table td ul li {
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  #section06 {
    position: relative;
    margin-bottom: 80px;
  }
  #section06 h2 {
    text-align: center;
    margin-bottom: 40px;
  }
  #section06 .container_in {
    background: #f9fdff;
    padding: 40px 0;
  }
  #section06 .container_in .wrapper .section06_contents {
    width: 100%;
    margin: 0;
    padding: 0;
    display: table;
  }
  #section06 .container_in .wrapper .section06_contents .section06_logo {
    width: 40%;
    display: block;
    vertical-align: middle;
    margin: 0 auto 40px auto;
  }
  #section06 .container_in .wrapper .section06_contents .section06_table {
    width: 100%;
    border-left: none;
  }
  #section06 .container_in .wrapper .section06_contents .section06_table table th {
    width: 100%;
    text-align: left;
    padding: 0;
    display: block;
  }
  #section06 .container_in .wrapper .section06_contents .section06_table table td {
    padding-bottom: 20px;
    font-size: 14px;
    display: block;
    padding: 0 0 20px;
  }
  #section06 .container_in .wrapper .section06_contents .section06_table table td ul li {
    font-size: 14px;
  }
}

#section07 {
  position: relative;
  margin-bottom: 120px;
}

#section07 h2 {
  text-align: center;
  margin-bottom: 120px;
}

#section07 .container_in {
  background: #f9fdff;
  padding: 80px 0;
}

#section07 .container_in .wrapper {
  margin: 0 auto;
}

#section07 .container_in .wrapper .section06_contents {
  width: 100%;
  margin: 0;
  padding: 0;
  display: table;
}

#section07 .container_in .wrapper .section06_contents .section06_logo {
  width: 35%;
  display: table-cell;
  vertical-align: middle;
}

#section07 .container_in .wrapper .section06_contents .section06_table {
  width: 65%;
  border-left: 1px solid #999;
}

#section07 .container_in .wrapper .section06_contents .section06_table table th {
  width: 20%;
  text-align: left;
  padding: 0 20px 0px;
  display: block;
}

#section07 .container_in .wrapper .section06_contents .section06_table table td {
  padding-bottom: 20px;
  font-size: 14px;
  display: block;
  padding: 0 40px 20px;
}

#section07 .container_in .wrapper .section06_contents .section06_table table td ul li {
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  #section07 {
    position: relative;
    margin-bottom: 120px;
  }
  #section07 h2 {
    text-align: center;
    margin-bottom: 40px;
  }
  #section07 .container_in {
    background: #f9fdff;
    padding: 80px 0;
  }
  #section07 .container_in .wrapper .section06_contents {
    width: 100%;
    margin: 0;
    padding: 0;
    display: table;
  }
  #section07 .container_in .wrapper .section06_contents .section06_logo {
    width: 35%;
    display: table-cell;
    vertical-align: middle;
  }
  #section07 .container_in .wrapper .section06_contents .section06_table {
    width: 65%;
    border-left: 1px solid #999;
  }
  #section07 .container_in .wrapper .section06_contents .section06_table table th {
    width: 20%;
    text-align: left;
    padding: 0 20px 0px;
    display: block;
  }
  #section07 .container_in .wrapper .section06_contents .section06_table table td {
    padding-bottom: 20px;
    font-size: 14px;
    display: block;
    padding: 0 40px 20px;
  }
  #section07 .container_in .wrapper .section06_contents .section06_table table td ul li {
    font-size: 14px;
  }
}

/*=============== to_top ==============*/
#page-top {
  position: fixed;
  bottom: 2%;
  right: 1%;
  z-index: 999999;
}

#page-top a:hover {
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  #page-top {
    right: 2%;
    bottom: 8%;
    width: 40px;
    height: 40px;
  }
}

/*-----------------------------------------------------------------------------------*/
/*お問い合わせフォーム----------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------*/
#section07 p {
  text-align: center;
  margin-bottom: 40px;
}

#section07 .wrapper {
  margin: 0 auto;
}

#section07 form {
  margin: 0 200px;
}

#section07 .contact_tel {
  border: 1px solid #999;
  margin: 0 auto 80px;
  display: block;
  width: 40%;
  text-align: center;
}

#section07 form table {
  width: 100%;
  margin-bottom: 20px;
}

#section07 form table th, #section07 form table td {
  padding: 16px 0;
  border-bottom: 1px solid #e5e5e5;
}

#section07 form table th {
  margin-bottom: 20px;
  vertical-align: middle;
}

#section07 form table td {
  margin-bottom: 20px;
}

#section07 form table th .required {
  background: #ff7d95;
  color: #fff;
  font-size: 12px;
  padding: 2px 4px;
  margin-left: 4px;
}

#section07 form input[type="text"], #section07 form input[type="tel"], #section07 form input[type="email"], #section07 form select, #section07 form textarea {
  font-size: 14px;
  width: 90%;
  margin: 0 auto;
  padding: 12px;
}

#section07 form textarea {
  height: 200px;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  #section07 form {
    margin: 0;
  }
}

@media screen and (max-width: 767px) {
  #section07 form {
    margin: 0 15px;
  }
  #section07 .contact_pnakuzu .pankuzu {
    margin-bottom: 60px;
  }
  #section07 .contact_tel {
    border: 1px solid #999;
    margin: 0px auto 40px;
    display: block;
    width: auto;
    text-align: center;
  }
  #section07 form table th {
    border: none;
    margin-bottom: 0;
    padding: 0;
  }
  #section07 form table th, #section07 form table td {
    display: block;
  }
}

/*-----------------------------------------------------------------------------------*/
/*確認画面----------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------*/
#confirm {
  padding: 80px 0;
}

#confirm .wrapper {
  background: #fff;
  padding: 120px 0;
}

#confirm h3 {
  text-align: center;
}

#confirm p {
  text-align: center;
}

.sectin04_table {
  padding: 0 40px;
}

.form_table {
  padding: 0 40px;
  width: 80%;
  margin: 0 auto 20px auto;
}

.button input[type="button"] {
  border: 1px solid #999;
  background: #FFF;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  padding: 16px 80px;
  display: block;
  margin: 0 auto 20px auto;
  box-sizing: border-box;
}

#confirm table tr:first-child {
  border-top: 1px dotted #999;
}

#confirm table tr {
  border-bottom: 1px dotted #999;
}

#confirm table td a {
  background: #28658c;
  color: #fff;
  padding: 16px 40px;
}

#confirm table th, #confirm table td {
  padding: 30px 0;
  line-height: 1.7em;
}

#confirm table th {
  width: 30%;
}

#confirm table th span {
  background: #999;
  color: #fff;
  font-weight: normal;
  margin-left: 6px;
  font-size: 12px;
  padding: 4px 8px;
}

#confirm table td {
  text-align: left;
  width: 70%;
}

#confirm table td a:before {
  background: url(../img/icn_pdf.png) #286d8c no-repeat;
  content: "";
  display: inline-block;
  background-size: 100%;
  background-position: 50%;
  vertical-align: middle;
  margin-right: 6px;
  width: 19px;
  height: 22px;
  color: #fff;
}

@media screen and (max-width: 767px) {
  #confirm {
    padding: 80px 10px;
  }
  #confirm .wrapper {
    padding: 0 0 20px;
  }
  #confirm p {
    text-align: left;
    padding: 0 20px;
  }
  #confirm h2 {
    color: #fff;
    background: #286d8c;
    font-size: 20px;
    padding: 1.8em;
    margin-bottom: 30px;
  }
  .sectin04_table {
    padding: 0;
  }
  .sectin04_table {
    padding: 0 20px;
  }
  #confirm table td a {
    background-color: transparent;
    color: #fff;
    padding: 0;
  }
  #confirm table td a:before {
    background: url(../img/icn_pdf.png) no-repeat;
    content: "";
    display: inline-block;
    background-size: 100%;
    background-position: 50%;
    vertical-align: middle;
    margin-right: 6px;
    width: 19px;
    height: 22px;
    color: #fff;
  }
}

/*-----------------------------------------------------------------------------------*/
/*完了画面----------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------*/
#thanks {
  padding: 120px 0;
}

#thanks .wrapper {
  background: #fff;
  padding: 120px 0;
}

#thanks h3 {
  text-align: center;
}

#thanks p {
  text-align: center;
}

@media screen and (max-width: 767px) {
  #thanks {
    padding: 80px 10px;
  }
  #thanks .wrapper {
    padding: 0 0 20px;
  }
  #thanks p {
    text-align: left;
    padding: 0 20px;
  }
  #thanks h2 {
    color: #fff;
    background: #286d8c;
    font-size: 20px;
    padding: 1.8em;
    margin-bottom: 30px;
  }
}

/*-----------------------------------------------------------------------------------*/
/*スライドショー----------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------*/
.slideshow {
  position: relative;
  width: 100%;
  padding-top: 50%;
  overflow: hidden;
  margin: 0 auto;
}

.slideshow .item {
  width: 100%;
  position: absolute;
  top: 0;
  display: none;
}

.in {
  -webkit-transform: scale(1);
  transform: scale(1);
  z-index: 2;
}

.out {
  -webkit-transform: scale(1.25);
  transform: scale(1.25);
  -webkit-transition: 4s;
  transition: 4s;
  z-index: 1;
}

.title {
  text-align: center;
  color: rgba(102, 102, 102, 0.7);
  margin: 20px 0 15px 0;
}

@media screen and (max-width: 767px) {
  .slideshow {
    position: relative;
    width: 100%;
    padding-top: 132%;
    overflow: hidden;
    margin: 0 auto;
  }
}

#section08 {
  margin-bottom: 120px;
}

@media screen and (max-width: 767px) {
  #section08 {
    margin-bottom: 60px;
  }
}

/*# sourceMappingURL=style.css.map */
