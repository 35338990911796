// ===================================================================
// 基本設定
// ===================================================================

// ブレイクポイントの設定
$width_pc: 1200px;
$width_tablet: 768px;
$width_sp: 480px;

//コンテンツの幅
$width_pc_contents: 1024px;

// 基本フォント
$line_hight: 1.6;
$base_font_size : 18px;//62.5%
$font_color: #1f1f1f;
$anc_color     : #416429　;
$base_color:#416429;

$base_font: "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", sans-serif;

$small:    "screen and (max-width:#{$width_sp})";
$middle:   "screen and (min-width:#{$width_tablet})";
$large:    "screen and (min-width:#{$width_pc})";
$retina:   "screen and (min-resolution: 2dppx)";
$between:  "only screen and (min-width:641px) and (max-width:959px)";


$pc:    "screen and (min-width: 768px)";
$sp:	"screen and (max-width: 767px)";