/*-----------------------------------------------------------------------------------*/
/*共通部分---------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------*/
body{
	font-size   : $base_font_size;
	color       : $font_color;
	font-family : $base_font;
	line-height : $line_hight;
	text-size-adjust: 100%;
	a{color:$anc_color;}
}
html, body {
	width: 100%;
	min-height: 100%;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: bordr-box;
	-o-box-sizing: boder-box;
	-ms-box-sizing: border-box;
}
body {
	font-family: 'Roboto', "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", sans-serif;
	-webkit-text-size-adjust: none;
	letter-spacing: 0.05em;
}
.mincho{
	font-family:"游明朝",YuMincho,"ヒラギノ明朝 Pro W3","Hiragino Mincho Pro","HG明朝E","MS P明朝","MS 明朝",serif;
}
p, li {
	text-align: justify;
	text-justify: inter-ideograph;
	line-height: 1.7em;
	font-size: 16px;
}
a {
	text-decoration: none;
	color: $base_color;
}
a:hover {
	text-decoration: none;
}
a[target="_blank"]:after {
    content: "";
    padding-left: 13px;
    background: url(../img/icn_outlink.png) no-repeat center left;
    background-size: 100%;
    width: 12px;
    height: 10px;
    margin-left: 4px;
}
img {
	max-width: 100%;
	height: auto;
	vertical-align: bottom;
}
li {
	list-style-type: none;
}
h2, h3 {
	line-height: 1.6em;
}
h2{
margin-bottom: 40px;
}
input[type="text"], input[type="tel"], input[type="email"], select, textarea {
	display: block;
	border:none;
	background: #f4f4f4;
	padding: 10px 24px;
	border-radius: none;
	-webkit-appearance: none;
}
input[type="submit"], input[type="button"] {
	border-radius: 0;
	-webkit-appearance: none;
	border: none;
}
input[type="button"] {
	display: inline-block;
	padding: 18px 50px;
	border: 1px solid #707070;
	background: #FFF;
	font-size: 18px;
	font-weight: bold;
	cursor: pointer;
	-webkit-appearance: none;
	border-radius: 0;
}
input[type="submit"] {
	background-position: center;
	background-color: #4d94ae;
	color: #fff;
	font-weight: bold;
	padding: 20px 80px;
	display: block;
	margin: 0 auto;
}
input[type="submit"]:hover {
	opacity: 0.8;
}
input[type="radio"].radio_design {
	display: none;
}
.clearfix::after {
	content: " ";
	display: block;
	clear: both;
}
.center {
	text-align: center;
}
.attention {
	font-size: 12px;
}

@media screen and (min-width: 768px) {
body {
	font-size: 14px;
	line-height: 1.7em;
}
p, li {
	text-align: justify;
	text-justify: inter-ideograph;
	line-height: 1.7em;
	font-size: 16px;
}
input[type="text"], input[type="tel"], input[type="email"], select, textarea {
	font-size: 14px;
}
input[type="radio"] + .radio:before {
	display: inline-block;
	content: "";
	margin-right: 10px;
	width: 1.8em;
	height: 1.8em;
	border: 1px solid #9e9e9e;
	background-color: #fff;
	vertical-align: middle;
	cursor: pointer;
}
input[type="radio"] + .radio {
	margin-right: 40px;
}
input[type="radio"]:checked + .radio:before {
	background-color: #960101;
}
}

@media screen and (max-width: 767px) {
body {
	font-size: 14px;
	line-height: 1.6em;
}
p, li {
	font-size: 14px;
}
input[type="text"], input[type="tel"], input[type="email"], select, textarea {
	font-size: 16px;
}
input[type="radio"] + .radio:before {
	display: inline-block;
	content: "";
	margin-right: 10px;
	width: 1em;
	height: 1em;
	border: 1px solid #9e9e9e;
	background-color: #fff;
	vertical-align: middle;
	cursor: pointer;
}
input[type="radio"] + .radio {
	margin-right: 10px;
	display: inline-block;
}
input[type="radio"]:checked + .radio:before {
	background-color: #960101;
}
}
/*-----PC・SP切り替え-----*/
@media #{$pc} {
.sp {
	display: none !important;
}
}

@media #{$sp} {
.pc {
	display: none !important;
}
}
.head,.footer{
			width: 100%;
}//.head,.footer

.container {
	max-width: 100%;
}
.container_in{
	margin: 0 80px;
}
.wrapper {
	max-width: $width_pc_contents;
	margin: 0 auto;
}
@media screen and (min-width:768px) and ( max-width:1023px) {
.container_in{
	margin: 0 4%;
}
}
@media #{$pc}  {
.container_in{
	margin: 0 80px;
}
.wrapper {
	margin: 0 4%;
}
}


@media #{$sp} {
.wrapper {
	padding: 0 20px;
}
.container_in{
	margin: 0 4%;
}
}